import axios from 'axios';
import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Collapse} from 'react-bootstrap';
import {useToasts} from 'react-toast-notifications';
import {logger} from 'src/lib';
import Import from '../Import';
import {CSVContent, entityColumnsDef, parseArray, parseDate, parseMetadata, UploadedEntityType} from './helper';
import {Alert, Button, Icon, Modal} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {CSVProcessedRows} from './CSVProcessedRows';

type UploadProps = {
  csvContent: CSVContent;
  resource: Resource;
  onClose: () => void;
};

export const UploadModal: React.ElementType = (props: UploadProps) => {
  const {addToast} = useToasts();

  const {csvContent, resource, onClose} = props;

  const [rows, setRows] = useState<any[]>();
  const [warnings, setWarnings] = useState<string[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [ready, setReady] = useState<boolean>(false);
  const [showOptional, setShowOptional] = useState<boolean>(false);
  const [data, setData] = useState<_.Dictionary<any>>({});
  const [filename, setFilename] = useState<string>('');

  const columnDefs = _.filter(entityColumnsDef(csvContent), {hide: false});
  const [mandatory, optional] = _.partition(columnDefs, 'isRequired');

  const onPreUpload = async () => {
    try {
      const {data} = await axios.post(`/api/connect/${resource.connectionId}/compare_upload`, {
        entities: rows,
        content: csvContent,
      });

      setData(data);
    } catch (err: any) {
      setData({});
    }
  };

  const onUpload = async (disableDeletionFor: any) => {
    try {
      const {additions = [], updates = []} = data || {};
      let entities = [...additions, ...updates];

      if (csvContent === CSVContent.ACCESS_RECORD) {
        entities = _.map(entities, (e: any) => {
          return e.originalData;
        });
      }

      const result = await axios.post(`/api/connect/${resource.connectionId}/upload_data`, {
        entities,
        options: {disableDeletionFor},
      });

      const message = result.status === 200 ? 'Succesfully uploaded' : 'An error occurred while uploading';
      const appearance = result.status === 200 ? 'success' : 'error';

      addToast(message, {appearance, autoDismiss: true});
      window.setTimeout(() => location.reload(), 1000);
      onClose();
    } catch (err: any) {
      setReady(false);
      let message = err?.response?.data?.error?.message;

      if (_.includes(message, '__parsed_extra')) {
        // message is: "entities[n].__parsed_extra" is not allowed
        let n;
        try {
          n = _.slice(_.split(message, 'entities')[1], 1, 2);
        } catch {
          n = undefined;
        }
        message = `Extra data columns provided ${n ? `on row #${n}.` : '.'}`;
      }

      setError(message || 'There has been an error');
    }
  };

  const handleData = async (uploadedData: _.Dictionary<any>, filename: string) => {
    setFilename(filename);

    try {
      setError(undefined);
      setWarnings([]);

      if (_.isEmpty(uploadedData)) {
        setReady(false);
        setError('No data uploaded.');
        return;
      }

      validateAndSetRows(uploadedData);
      setReady(true);
    } catch (err: any) {
      setReady(false);
      setShowOptional(false);
    }
  };

  useEffect(() => {
    async function onRowsSet() {
      await onPreUpload();
    }

    if (!_.isEmpty(rows)) {
      void onRowsSet();
    }
  }, [rows]);

  function validateAndSetRows(data: _.Dictionary<any>) {
    const eventType = csvContent === CSVContent.ACCESS_RECORD ? 'account' : csvContent?.toString();

    const validatedData: {id: string; type: string}[] = _.map(
      _.filter(data, (r) => {
        return !!r.id;
      }),
      (row: any) => {
        const trimmedRow = _.mapValues(row, (v) => _.trim(v));
        const isAccountOrAccess = eventType === CSVContent.ACCOUNT || eventType === CSVContent.ACCESS_RECORD;
        const isResOrPerm = eventType === CSVContent.RESOURCE_PERMISSION;
        trimmedRow.type = _.toLower(trimmedRow.type || eventType);

        if (csvContent && trimmedRow.type) {
          if (
            (isAccountOrAccess && trimmedRow.type !== UploadedEntityType.ACCOUNT) ||
            (isResOrPerm && !_.includes([UploadedEntityType.RESOURCE, UploadedEntityType.PERMISSION], trimmedRow.type))
          ) {
            setError(`Unallowed operation: '${trimmedRow.type}' entity type cannot be uploaded in this step`);
            throw error;
          }
        }

        return {
          ...trimmedRow,
          id: _.toString(trimmedRow.id),
          createdDate: parseDate(trimmedRow, trimmedRow.createdDate, setError),
          deletedDate: parseDate(trimmedRow, trimmedRow.deletedDate, setError),
          metadata: parseMetadata(trimmedRow, setError),
          type: trimmedRow.type,
          resource: !_.isNil(trimmedRow.resource) ? _.toString(trimmedRow.resource) : undefined,
          permissions: parseArray(trimmedRow, trimmedRow.permissions, setError),
        };
      }
    );

    const processed = _.groupBy(
      _.map(validatedData, (row) => {
        return {idByType: row.type.concat('#').concat(row.id), id: row.id};
      }),
      'idByType'
    );

    _.each(processed, (grouped) => {
      if (_.size(grouped) > 1) {
        setError(`IDs must be set and unique: '${grouped[0].id}' repeated ${_.size(grouped)} times.`);
        throw error;
      }
    });

    const warn: string[] = [];
    const ignoredColMsg = (columnName: string, type: string) => {
      return `'${columnName}' column on ${type} rows will be ignored`;
    };

    _.each(validatedData, (row: any) => {
      logger.log('Processing row ID', row.id, row.type);

      const isEventOrResource = _.includes([UploadedEntityType.EVENT, UploadedEntityType.RESOURCE], row.type);
      const isEventAccountOrResource = _.includes(
        [UploadedEntityType.EVENT, UploadedEntityType.ACCOUNT, UploadedEntityType.RESOURCE],
        row.type
      );

      if (isEventOrResource) {
        if (!_.isEmpty(row.permissions)) {
          warn.push(ignoredColMsg('permissions', row.type));
          row.permissions = undefined;
        }
      }

      if (isEventAccountOrResource) {
        if (!_.isEmpty(row.resource)) {
          warn.push(ignoredColMsg('resource', row.type));
          row.resource = undefined;
        }
      }

      if (row.type === UploadedEntityType.EVENT) {
        if (!_.isEmpty(row.id)) {
          warn.push(ignoredColMsg('id', row.type));
          row.id = undefined;
        }
        if (!_.isEmpty(row.deletedDate)) {
          warn.push(ignoredColMsg('deletedDate', row.type));
          row.deletedDate = undefined;
        }
      }
    });

    setWarnings(_.uniq(warn));
    setRows(validatedData.concat((rows || []).filter((r) => !_.find(validatedData, {id: r.id}))));
  }

  const title =
    csvContent === CSVContent.RESOURCE_PERMISSION ? 'Resources and Permissions'
      : CSVContent.ACCESS_RECORD ? 'Accesses'
      : _.startCase(_.toString(csvContent)).concat('s');

  return (
    <Modal title={!ready ? `Uploading CSV: ${title}` : 'Ready to Upload'} width={'xl'} onClose={onClose}>
      <>
        {!_.isEmpty(warnings) && (
          <Alert colorVariant={'warning'} onClose={() => setWarnings([])}>
            <div>
              {warnings.map((message) => (
                <div key={message}>{message}</div>
              ))}
            </div>
          </Alert>
        )}
        {error && (
          <Alert colorVariant={'danger'} onClose={() => setError(undefined)}>
            {error}
          </Alert>
        )}
        {!ready && (
          <div>
            <a
              href={'/docs/connectors/universal/csv_guide'}
              rel="noopener noreferrer"
              target="_blank"
              className="text-underline"
            >
              <p style={{fontSize: 'small'}}>Documentation</p>
            </a>
            <div className={'border-bottom mb-3'}>
              <p className={'mb-1'}>
                <strong>Required Columns</strong>
              </p>
            </div>
            <div className={'pb-4'}>
              {mandatory.map((column: any) => (
                <div className={'mb-1'} key={column.key}>
                  <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
                  <br />
                  <div className={'text-muted'}>
                    {column.conditions &&
                      `${
                        _.isArray(column.conditions)
                          ? 'One of: '.concat(column.conditions.join(', '))
                          : column.conditions
                      }`}
                  </div>
                </div>
              ))}
            </div>
            <div className={'pt-1 pb-5'} onClick={() => setShowOptional(!showOptional)}>
              <strong>Optional columns</strong>
              <Icon
                className={'ml-3'}
                color={'black'}
                type={showOptional ? 'chevronUp' : 'chevronDown'}
                onClick={() => {
                  setShowOptional(!showOptional);
                }}
              />
              <Collapse in={showOptional}>
                <div className={'border-top mt-2 pt-3'}>
                  {optional.map((column: any) => (
                    <div className={'mb-1'} key={column.key}>
                      <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
                      <br />
                      <div className={'text-muted'}>
                        {column.conditions &&
                          `${
                            _.isArray(column.conditions)
                              ? 'One of: '.concat(column.conditions.join(', '))
                              : column.conditions
                          }`}
                      </div>
                    </div>
                  ))}
                </div>
              </Collapse>
            </div>
          </div>
        )}
        <div>
          {ready ? (
            <CSVProcessedRows
              onClose={() => {
                setError(undefined);
                setWarnings([]);
                setRows([]);
                setReady(false);
                setData({});
              }}
              onConfirm={onUpload}
              syncInProgress={false}
              csvContent={csvContent}
              data={data}
              filename={filename}
            />
          ) : (
            <div>
              <Import handleUpload={handleData} handleError={setError} headerTransform={'camelcase'} />
              <div className="d-flex justify-content-between mt-5">
                <Button
                  className="btn btn-secondary font-weight-bold"
                  onClick={() => {
                    setRows([]);
                    onClose();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        </div>
      </>
    </Modal>
  );
};

import React from 'react';
import {Icon} from '@trustle/component-library';

export const COLUMNS = [
  {
    dataField: 'name',
    text: 'Licenses',
    formatter: (name: string) => (
      <div className="tr-flex tr-items-center tr-gap-1">
        <Icon size="sm" type="licenses" color="white" />
        <span className="tr-text-trustle-link">{name}</span>
      </div>
    ),
  },
];

import React, {Dispatch, useMemo} from 'react';
import {Button, Checkbox, Table} from '@trustle/component-library';
import {COLUMNS} from './config';
import {License} from '../../types';

type Props = {
  licenses: License[];
  selectedLicenses: string[];
  setSelectedLicenses: Dispatch<React.SetStateAction<string[]>>;
  summarizeAllLicenses: boolean;
  setSummarizeAllLicenses: Dispatch<React.SetStateAction<boolean>>;
  setShowLicensesPanel: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAX_LICENSES_SELECTED = 4;

export function LicensesTable({
  licenses,
  selectedLicenses,
  setSelectedLicenses,
  summarizeAllLicenses,
  setSummarizeAllLicenses,
  setShowLicensesPanel,
}: Props) {
  const selectLicensesHandler = (row: {id: string}, isSelect: boolean) => {
    if (isSelect) {
      setSummarizeAllLicenses(false);
      setSelectedLicenses((prev) => {
        if (prev.length < MAX_LICENSES_SELECTED) {
          return [...prev, row.id];
        }
        return [...prev.slice(1), row.id];
      });
    } else {
      if (selectedLicenses.length === 1) {
        setSummarizeAllLicenses(true);
      }
      setSelectedLicenses((prev) => prev.filter((license) => license !== row.id));
    }
  };

  const nonSelectableLicenses = useMemo(() => {
    const licensesKeys = licenses.map((license) => license.id);
    return selectedLicenses.length === MAX_LICENSES_SELECTED
      ? licensesKeys.filter((license) => !selectedLicenses.includes(license))
      : [];
  }, [selectedLicenses, summarizeAllLicenses]);

  return (
    <div className="tr-flex tr-flex-col">
      <Button
        variant="secondary"
        className="tr-m-0 tr-px-3 tr-py-2.5 tr-whitespace-nowrap tr-ml-auto tr-bg-trustle-bglight tr-text-black"
        onClick={() => setShowLicensesPanel(false)}
      >
        Hide Licenses
      </Button>
      <h2>Licenses</h2>
      <div className="tr-flex tr-gap-2 tr-items-center tr-mb-2">
        <div>
          <Checkbox
            id="overall-license-usage"
            name="overall-license-usage"
            checked={summarizeAllLicenses}
            onChange={(e: any) => {
              if (e.target.checked) {
                setSelectedLicenses([]);
              }
              setSummarizeAllLicenses(e.target.checked);
            }}
          />
        </div>
        <span className="tr-flex tr-gap-2 tr-whitespace-nowrap">
          <strong>Overall Utilization</strong>
          <p className="tr-font-light tr-mb-0">(Or select up to 4 to compare)</p>
        </span>
      </div>
      <Table
        overrideKeyField="id"
        tableKey="licenses"
        data={licenses}
        columns={COLUMNS}
        hideSizePerPage
        selectRow={{
          mode: 'checkbox',
          clickToSelect: false,
          hideSelectAll: true,
          selected: selectedLicenses,
          onSelect: selectLicensesHandler,
          nonSelectable: nonSelectableLicenses as any,
        }}
      />
    </div>
  );
}

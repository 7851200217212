import {AuthorityServiceE} from '../../../../types';

export const azureFields = {
  [AuthorityServiceE.AZURE_AD]: {
    'Display Name': 'displayName',
    'First Name': 'givenName',
    'Last Name': 'surname',
    'User Principal Name': 'userPrincipalName',
    Email: 'mail',
    Manager: 'manager.mail',
    'Employee Type': 'userType',
    Department: 'department',
    Title: 'jobTitle',
    Role: 'role',
  },
};

import _ from 'lodash';
import {ColumnDescription} from 'react-bootstrap-table-next';
import React from 'react';
import {IconTooltip} from '../../../../components/IconTooltip';
import {AuthorityServiceE, FieldMappingAttrType} from '../../../../types';
import {azureFields} from './azureFields';
import {oktaFields} from './oktaFields';
import {gappsFields} from './gappsFields';

export type FieldMappingOption = {
  id: string;
  value: string;
  label: string;
};

function mapServiceFieldsToOptions(service: AuthorityServiceE) {
  const fields = SERVICE_AVAILABLE_FIELDS[service];
  return Object.keys(fields).map((displayName) => ({
    id: fields[displayName],
    value: fields[displayName],
    label: displayName,
  }));
}

const SERVICE_AVAILABLE_FIELDS: Record<AuthorityServiceE, any> = {
  ...azureFields,
  ...oktaFields,
  ...gappsFields,
};

export const SERVICE_FIELD_MAPPING_OPTIONS: Record<AuthorityServiceE, FieldMappingOption[]> = {
  [AuthorityServiceE.AZURE_AD]: mapServiceFieldsToOptions(AuthorityServiceE.AZURE_AD),
  [AuthorityServiceE.OKTA]: mapServiceFieldsToOptions(AuthorityServiceE.OKTA),
  [AuthorityServiceE.GAPPS]: mapServiceFieldsToOptions(AuthorityServiceE.GAPPS),
};

const defaultParams = {mandatory: false, regex: undefined};
export type FieldMappingType = _.Dictionary<{name: string; mandatory: boolean; regex?: string}>;

export const SERVICE_FIELD_MAPPINGS: Record<AuthorityServiceE, FieldMappingType> = {
  [AuthorityServiceE.AZURE_AD]: {
    [FieldMappingAttrType.EMAIL]: {name: 'mail', mandatory: true},
    [FieldMappingAttrType.MANAGER]: {name: 'manager.mail', ...defaultParams},
    [FieldMappingAttrType.USER_TYPE]: {name: 'userType', ...defaultParams},
    [FieldMappingAttrType.DEPARTMENT]: {name: 'department', ...defaultParams},
    [FieldMappingAttrType.ROLE]: {name: 'role', ...defaultParams},
    [FieldMappingAttrType.TITLE]: {name: 'jobTitle', ...defaultParams},
    // Trustle user type mapping
    [FieldMappingAttrType.EMPLOYEE]: {name: 'userType', mandatory: false, regex: '^employee$'},
    [FieldMappingAttrType.CONTRACTOR]: {name: 'userType', mandatory: false, regex: '^contractor$'},
    [FieldMappingAttrType.CUSTOMER]: {name: 'userType', mandatory: false, regex: '^customer$'},
    [FieldMappingAttrType.SYSTEM]: {name: 'userType', mandatory: false, regex: '^system$'},
  },
  [AuthorityServiceE.OKTA]: {
    [FieldMappingAttrType.EMAIL]: {name: 'email', ...defaultParams},
    [FieldMappingAttrType.MANAGER]: {name: 'manager', ...defaultParams},
    [FieldMappingAttrType.USER_TYPE]: {name: 'title', ...defaultParams},
    [FieldMappingAttrType.DEPARTMENT]: {name: 'department', ...defaultParams},
    [FieldMappingAttrType.ROLE]: {name: 'honorificPrefix', ...defaultParams},
    [FieldMappingAttrType.TITLE]: {name: 'title', ...defaultParams},
    // Trustle user type mapping
    [FieldMappingAttrType.EMPLOYEE]: {name: 'title', mandatory: false, regex: '^employee$'},
    [FieldMappingAttrType.CONTRACTOR]: {name: 'title', mandatory: false, regex: '^contractor$'},
    [FieldMappingAttrType.CUSTOMER]: {name: 'title', mandatory: false, regex: '^customer$'},
    [FieldMappingAttrType.SYSTEM]: {name: 'title', mandatory: false, regex: '^system$'},
  },
  [AuthorityServiceE.GAPPS]: {
    [FieldMappingAttrType.EMAIL]: {name: 'primaryEmail', mandatory: true},
    [FieldMappingAttrType.MANAGER]: {name: 'manager', ...defaultParams},
    [FieldMappingAttrType.USER_TYPE]: {name: 'employeeInfo.description', ...defaultParams},
    [FieldMappingAttrType.DEPARTMENT]: {name: 'employeeInfo.department', ...defaultParams},
    [FieldMappingAttrType.ROLE]: {name: 'employeeInfo.customType', ...defaultParams},
    [FieldMappingAttrType.TITLE]: {name: 'employeeInfo.title', ...defaultParams},
    // Trustle user type mapping
    [FieldMappingAttrType.EMPLOYEE]: {name: 'employeeInfo.description', mandatory: false, regex: '^employee$'},
    [FieldMappingAttrType.CONTRACTOR]: {name: 'employeeInfo.description', mandatory: false, regex: '^contractor$'},
    [FieldMappingAttrType.CUSTOMER]: {name: 'employeeInfo.description', mandatory: false, regex: '^customer$'},
    [FieldMappingAttrType.SYSTEM]: {name: 'employeeInfo.description', mandatory: false, regex: '^system$'},
  },
};

// Adapter logic to support old and new format
type AuthoritySettingsOld = {
  service: string;
  fieldMapping: Record<string, string>;
  connectionId: string;
};

type AuthoritySettingsNew = {
  service: string;
  fieldMapping: Record<string, {name: string; mandatory: boolean; regex?: string}>;
  connectionId: string;
};

export class AuthoritySettingsAdapter {
  private readonly settings: AuthoritySettingsOld | AuthoritySettingsNew;
  private readonly service: AuthorityServiceE;
  private readonly existingKeys: string[];
  private readonly missingKeys: string[];
  private readonly isNewFormat: boolean;

  constructor(settings: any, service: AuthorityServiceE) {
    this.settings = settings;
    this.service = service;
    this.existingKeys = Object.keys(this.settings.fieldMapping);
    this.missingKeys = _.difference(Object.keys(SERVICE_FIELD_MAPPINGS[this.service]), this.existingKeys);
    this.isNewFormat = typeof this.settings.fieldMapping.email === 'object';
  }

  // Method to adapt data from old format to new format
  adaptToNewFormat(): AuthoritySettingsNew {
    if (this.isNewFormat) {
      // Data is already in the new format
      return this.settings as AuthoritySettingsNew;
    } else {
      // Data is in the old format, perform adaptation
      const existingKeysAdapted = this.existingKeys.reduce((accumulator: any, existingKey: string) => {
        accumulator[existingKey] = {
          name: this.settings.fieldMapping[existingKey],
          mandatory: existingKey === 'email',
          regex: undefined,
        };
        return accumulator;
      }, {} as any);

      const missingKeysToDefault = this.missingKeys.reduce((accumulator: any, missingKey: string) => {
        accumulator[missingKey] = SERVICE_FIELD_MAPPINGS[this.service][missingKey];
        return accumulator;
      }, {} as any);

      return {
        service: this.settings.service,
        fieldMapping: {...existingKeysAdapted, ...missingKeysToDefault},
        connectionId: this.settings.connectionId,
      } as AuthoritySettingsNew;
    }
  }

  // Method to adapt data from new format to old format
  adaptToOldFormat(): AuthoritySettingsOld {
    const keysAvailableForOldFormat = ['department', 'role', 'title', 'manager', 'email', 'type'];
    if (this.isNewFormat) {
      // Data is in the new format, perform adaptation
      const existingKeysAdapted = this.existingKeys.reduce((accumulator: any, existingKey: string) => {
        if (keysAvailableForOldFormat.includes(existingKey)) {
          const value = this.settings.fieldMapping[existingKey] as {name: string; mandatory: boolean; regex?: string};
          accumulator[existingKey] = value.name || '';
        }
        return accumulator;
      }, {} as any);

      return {
        service: this.settings.service,
        fieldMapping: {...existingKeysAdapted},
        connectionId: this.settings.connectionId,
      } as AuthoritySettingsOld;
    } else {
      // Data is already in the old format
      return this.settings as AuthoritySettingsOld;
    }
  }
}

export function renderRowIconForMandatoryField(column: ColumnDescription, fieldMapping: any) {
  const fieldIsMandatory = _.get(fieldMapping, column.dataField)?.mandatory;
  const {formatter: originalFormatter, ...rest} = column;
  return {
    ...rest,
    formatter: (field: string, record: any, rowIndex: number) => {
      if (fieldIsMandatory && _.isEmpty(field)) {
        return (
          <IconTooltip
            description="This field is mandatory. Please provide a value."
            className="text-danger"
            type="warning"
          />
        );
      }
      return originalFormatter ? originalFormatter(field, record, rowIndex) : <>{field}</>;
    },
  };
}

import {useState} from 'react';
import axios from 'axios';
import {LicenseUsageResponse, ViewType} from '../types';
import {VIEW_TYPE_TO_DAYS} from '../constants';

type FetchParams = {
  licenses: string[];
  view: ViewType;
  accountExternalId?: string;
  allLicenses?: boolean;
};

type Props = {
  resourceId: string;
};

export function useLicenseUsage({resourceId}: Props) {
  const [data, setData] = useState<LicenseUsageResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = `/api/resources/${resourceId}/licenses/timeline`;

  const fetchLicenseUsage = async (params: FetchParams) => {
    const {licenses, view, accountExternalId, allLicenses = false} = params;

    const parsedParams: any = {
      days: VIEW_TYPE_TO_DAYS[view],
      licenses: licenses,
      all_licenses: allLicenses,
    };

    if (accountExternalId) {
      parsedParams.account_external_id = accountExternalId;
    }

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<LicenseUsageResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchLicenseUsage};
}

import {ApexOptions} from 'apexcharts';

export const HEATMAP_COLORS = ['#DCDEDF', '#EBE3FF', '#C4B1FC', '#855CF8', '#6144B2'];
export const HEAT_MAP_OPTIONS: ApexOptions = {
  chart: {
    id: 'responsive-heatmap',
    toolbar: {
      show: false,
    },
  },
  stroke: {
    show: true,
    colors: ['#ffffff'],
    width: 1,
  },
  tooltip: {
    y: {
      formatter: (value: number) => {
        return `${value}%`; // Appends '%' to the value
      },
    },
  },
  xaxis: {
    position: 'top', // Place x label on top of the chart
    labels: {
      rotate: 0, // Rotate the labels to 0 degrees (horizontal)
    },
  },
  legend: {
    show: false, // Disable default legend we use a custom one
  },
  dataLabels: {
    enabled: false, // Disable data labels on heatmap cells
  },
  plotOptions: {
    heatmap: {
      enableShades: false,
      colorScale: {
        ranges: [
          {
            from: 0,
            to: 0,
            color: HEATMAP_COLORS[0],
          },
          {
            from: 1,
            to: 25,
            color: HEATMAP_COLORS[1],
          },
          {
            from: 26,
            to: 50,
            color: HEATMAP_COLORS[2],
          },
          {
            from: 51,
            to: 75,
            color: HEATMAP_COLORS[3],
          },
          {
            from: 76,
            to: 100,
            color: HEATMAP_COLORS[4],
          },
        ],
      },
    },
  },
};

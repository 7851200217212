import {ViewType} from './types';

export const ALL_LICENSES_KEY = 'all_licenses';
export const VIEWS = ['year', 'quarter', 'month', 'week'] as const;
export const HEATMAP_SERIES = ['department', 'remote_role', 'team_name', 'title'] as const;

export const NO_DATA_MESSAGES = {
  NO_LICENSE_DATA: 'There is no license data to display',
  LOADING_DATA: 'Data is being loaded',
  TIME_RANGE_NO_DATA: 'There is no license data available for this time range',
  NO_LICENSES_SELECTED: 'Please select at least one license',
};

export const VIEW_TYPE_TO_DAYS: Record<ViewType, number> = {
  year: 365,
  quarter: 90,
  month: 30,
  week: 7,
};

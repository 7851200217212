import React, {useEffect, useMemo, useState} from 'react';
import {LicensesTable} from './internal/licenses-table/LicensesTable';
import _ from 'lodash';
import {LicenseUsageOverview} from './internal/license-usage-overview/LicenseUsageOverview';
import {DashboardCard} from '../DashboardCard';
import {LicenseUsageHeatMap} from './internal/license-usage-heat-map/LicenseUsageHeatMap';
import {Icon, Tab, Tooltip} from '@trustle/component-library';
import {useLicenseUsage} from './hooks/useLicenseUsage';
import {ViewType} from './types';
import {ALL_LICENSES_KEY} from './constants';
import {formatTimestamp, groupUsageByPeriod, LicenseUsageWithLicense} from './utils';
import {LicensesUsageChart} from './internal/licenses-usage-chart/LicensesUsageChart';
import {useResourceFirstSuccessImport} from './hooks/useResourceFirstSuccessImport';
import {useDebounce} from '../../lib';
import {LICENSES_AVAILABLE_COLORS, uniqueColorProvider, USAGE_COLOR} from './internal/licenses-usage-chart/utils';
import {useLicenses} from './hooks/useLicenses';

const DEFAULT_VIEW: {value: ViewType; label: string} = {
  value: 'week',
  label: 'Last 7 days',
};

type Props = {
  resourceId: string;
  showHeatmap?: boolean; // Default to false
  accountExternalId?: string;
};

const BUCKET_SIZE = 12;

export function M365LicenseUsageCard({showHeatmap = false, accountExternalId, resourceId = ''}: Props) {
  const [selectedLicenses, setSelectedLicenses] = useState<string[]>([]);
  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [summarizeAllLicenses, setSummarizeAllLicenses] = useState(true);
  const [showLicensesPanel, setShowLicensesPanel] = useState(true);
  const [heatmapLoading, setHeatmapLoading] = useState(false);

  const {data: firstSuccessImport} = useResourceFirstSuccessImport({resourceId});

  const {data: availableLicenses = {licenses: []}, fetchLicenses} = useLicenses({resourceId});

  useEffect(() => {
    void fetchLicenses({
      accountExternalId,
    });
  }, []);

  const {
    data: licenseUsageData,
    fetchLicenseUsage,
    loading: loadingLicenseUsage,
  } = useLicenseUsage({
    resourceId,
  });

  const debouncedFetchLicenseUsage = useDebounce(fetchLicenseUsage, 1200);

  useEffect(() => {
    void debouncedFetchLicenseUsage({
      licenses: selectedLicenses,
      view: selectedView.value,
      accountExternalId,
      allLicenses: summarizeAllLicenses,
    });
  }, [selectedLicenses, selectedView, accountExternalId, summarizeAllLicenses]);

  const licensesByKeyMap = useMemo(() => {
    const selected = availableLicenses.licenses.filter((license) => selectedLicenses.includes(license.id));
    const {getColor} = uniqueColorProvider(LICENSES_AVAILABLE_COLORS);
    const map: Record<string, {name: string; color?: string}> = {
      [ALL_LICENSES_KEY]: {name: 'All Licenses', color: USAGE_COLOR},
    };
    selected.forEach((license) => {
      map[license.id] = {
        name: license.name,
        color: getColor(),
      };
    });
    return map;
  }, [selectedLicenses]);

  const FILTERED_LICENSE_USAGE_DATA = useMemo(() => {
    if (!licenseUsageData?.license_usage) {
      return [];
    }

    let preprocessedData: LicenseUsageWithLicense[] = [];
    const usageSource = licenseUsageData?.license_usage;
    Object.keys(usageSource).forEach((licenseKey) => {
      const dataWithLicenseKey = usageSource[licenseKey].map((usage) => ({
        ...usage,
        license: licenseKey,
      }));
      preprocessedData = [...preprocessedData, ...dataWithLicenseKey];
    });

    preprocessedData = preprocessedData.sort(
      (a, b) => new Date(a.reporting_period).getTime() - new Date(b.reporting_period).getTime()
    );

    let groupedData = _.groupBy(preprocessedData, 'reporting_period');

    if (selectedView.value !== 'week') {
      groupedData = groupUsageByPeriod(groupedData, BUCKET_SIZE);
    }

    return Object.keys(groupedData).map((key, index) => {
      return {
        name: key,
        date: selectedView.value !== 'week' ? key.split('|')[0] : key,
        data: groupedData[key].reduce((acc, curr) => {
          acc[curr.license] = curr;
          return acc;
        }, {} as any),
        index,
      };
    });
  }, [licenseUsageData]);

  if (licenseUsageData === undefined) {
    return null;
  }

  return (
    <DashboardCard loading={loadingLicenseUsage || heatmapLoading}>
      <div className="tr-flex tr-gap-2 tr-p-4">
        <div className="tr-flex tr-flex-col tr-w-full tr-gap-2 ">
          <LicenseUsageOverview
            summary={licenseUsageData?.summary_statistics || {total_usage_hours: 0, total_access_hours: 0}}
            selectedView={selectedView}
            setSelectedView={setSelectedView}
            showLicensesPanel={showLicensesPanel}
            setShowLicensesPanel={setShowLicensesPanel}
          />
          <div className="tr-p-2">
            {showHeatmap ? (
              <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
                <Tab.List variant="line">
                  <Tab key={'BARCHART'}>Timeline</Tab>
                  <Tab key={'HEATMAP'}>Heatmap</Tab>
                  <RefreshDateTime refreshed_datetime={licenseUsageData?.summary_statistics?.refreshed_datetime} />
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel key={'BARCHART'}>
                    <LicensesUsageChart
                      data={FILTERED_LICENSE_USAGE_DATA}
                      series={summarizeAllLicenses ? [ALL_LICENSES_KEY] : selectedLicenses}
                      summarizeAllLicenses={summarizeAllLicenses}
                      onboardDateStr={firstSuccessImport?.first_success_import?.created}
                      licenseByKeyMap={licensesByKeyMap}
                    />
                  </Tab.Panel>
                  <Tab.Panel key={'HEATMAP'}>
                    <LicenseUsageHeatMap
                      view={selectedView.value}
                      licenses={selectedLicenses}
                      resourceId={resourceId}
                      setHeatmapLoading={setHeatmapLoading}
                      showLicensesPanel={showLicensesPanel}
                      summarizeAllLicenses={summarizeAllLicenses}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            ) : (
              <div className="tr-flex tr-flex-col tr-gap-2">
                <RefreshDateTime refreshed_datetime={licenseUsageData?.summary_statistics?.refreshed_datetime} />
                <LicensesUsageChart
                  data={FILTERED_LICENSE_USAGE_DATA}
                  series={summarizeAllLicenses ? [ALL_LICENSES_KEY] : selectedLicenses}
                  summarizeAllLicenses={summarizeAllLicenses}
                  onboardDateStr={firstSuccessImport?.first_success_import?.created}
                  licenseByKeyMap={licensesByKeyMap}
                />
              </div>
            )}
          </div>
        </div>
        {showLicensesPanel && (
          <div className="tr-max-w-[350px] tr-border-gray-200 tr-border-[0px] tr-border-l-[1px] tr-border-solid tr-px-4">
            <LicensesTable
              licenses={availableLicenses.licenses}
              selectedLicenses={selectedLicenses}
              setSelectedLicenses={setSelectedLicenses}
              summarizeAllLicenses={summarizeAllLicenses}
              setSummarizeAllLicenses={setSummarizeAllLicenses}
              setShowLicensesPanel={setShowLicensesPanel}
            />
          </div>
        )}
      </div>
    </DashboardCard>
  );
}

function RefreshDateTime({refreshed_datetime}: {refreshed_datetime: string}) {
  if (!refreshed_datetime) {
    return null;
  }
  return (
    <div className="tr-flex tr-items-center tr-ml-auto tr-gap-1">
      <span className="tr-text-gray-400">{`Data shown is current as of: ${formatTimestamp(refreshed_datetime)}`}</span>
      <Tooltip
        size="md"
        position="bottom"
        content={
          <div className="tr-flex tr-flex-col">
            <strong>Report Refresh Date</strong>
            <p>This data is limited by when M365 updates its license data.</p>
            <p>Trustle runs its imports every 72 hours.</p>
            <p>This information was current as of:</p>
            <strong>{formatTimestamp(refreshed_datetime)}</strong>
          </div>
        }
      >
        <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
      </Tooltip>
    </div>
  );
}

import React, {PropsWithChildren} from 'react';
type Props = PropsWithChildren<{
  src?: string;
}>;
const NoData = ({children, src = '/russel-thinking.svg'}: Props) => {
  return (
    <div className="tr-text-center tr-mt-4 tr-pt-4 tr-w-full">
      <img src={src} className="tr-m-2" style={{width: '190px'}} alt="" />
      <div className="body5 tr-mt-3">{children}</div>
    </div>
  );
};

export default NoData;

import React, {useEffect, useMemo, useState} from 'react';
import Chart from 'react-apexcharts';
import {HEAT_MAP_OPTIONS} from './config';
import {Select} from '@trustle/component-library';
import {CustomLegend} from './internal/CustomLegend';
import './ApexHeatmap.css';
import {useLicenseHeatmap} from '../../hooks/useLicenseHeatmap';
import {HeatmapSeriesType, ViewType} from '../../types';
import NoData from '../../../../components/NoData';
import {HeatmapPagination} from './internal/HeatmapPagination';

const OPTIONS: {value: HeatmapSeriesType; label: string}[] = [
  {
    value: 'department',
    label: 'Departments',
  },
  {
    value: 'remote_role',
    label: 'Roles',
  },
  {
    value: 'team_name',
    label: 'Teams',
  },
  {
    value: 'title',
    label: 'Titles',
  },
];

type Props = {
  view: ViewType;
  licenses: string[];
  resourceId: string;
  setHeatmapLoading: (value: boolean) => void;
  showLicensesPanel: boolean;
  summarizeAllLicenses?: boolean;
};

const AVERAGE_LABEL = 'Average';
const Y_AXIS_PAGE_SIZE = 10;
const X_AXIS_PAGE_SIZE = 10;

// Workaround to handel unexpected values for heatmap rows/cols labels
const resolveRowColLabel = (value: any) => {
  if (!value || value === 'NULL') {
    return 'Unassigned';
  }
  return value;
};

const refreshChart = async (series: ApexAxisChartSeries | ApexNonAxisChartSeries) => {
  const chartInstance = ApexCharts.getChartByID('responsive-heatmap');
  if (chartInstance) {
    void chartInstance.updateSeries(series, true);
  }
};

export function LicenseUsageHeatMap({
  view,
  licenses,
  resourceId,
  setHeatmapLoading,
  showLicensesPanel,
  summarizeAllLicenses,
}: Props) {
  const [selectedYValue, setSelectedYValue] = useState<{value: HeatmapSeriesType; label: string}>();
  const [selectedXValue, setSelectedXValue] = useState<{value: HeatmapSeriesType; label: string}>();

  const [xAxisPage, setXAxisPage] = useState(0);
  const [yAxisPage, setYAxisPage] = useState(0);

  const {data: heatmapData, fetchLicenseUsageHeatmap, loading} = useLicenseHeatmap({resourceId});
  useEffect(() => {
    if (selectedYValue && selectedXValue) {
      void fetchLicenseUsageHeatmap({
        view,
        licenses,
        x_attribute: selectedXValue.value,
        y_attribute: selectedYValue.value,
        allLicenses: summarizeAllLicenses,
      });
    }
  }, [licenses, view, selectedXValue, selectedYValue]);

  useEffect(() => {
    setHeatmapLoading(loading);
  }, [loading]);

  const series = useMemo(() => {
    if (!heatmapData || !heatmapData.data || !heatmapData.data.length) {
      return [];
    }
    const {heatmap_rows, heatmap_columns, data, heatmap_column_means, heatmap_row_means} = heatmapData;
    // Calculates all series for the heatmap
    const series = heatmap_rows.map((rowName, rowIndex) => {
      return {
        name: resolveRowColLabel(rowName),
        data: heatmap_columns.map((colName, colIndex) => {
          return {
            x: resolveRowColLabel(colName),
            y: Math.round(data[rowIndex][colIndex] * 100),
          };
        }),
      };
    });

    const pagedSeries = paginateSeries(series, xAxisPage, yAxisPage);

    // Calculates for column average (Adds new row average)
    const columnMeanSeries = [
      {
        name: AVERAGE_LABEL,
        data: [
          ...heatmap_columns
            .slice(xAxisPage * X_AXIS_PAGE_SIZE, (xAxisPage + 1) * X_AXIS_PAGE_SIZE)
            .map((colName, colIndex) => {
              return {
                x: resolveRowColLabel(colName),
                y: Math.round(heatmap_column_means[colIndex] * 100),
              };
            }),
          {
            x: AVERAGE_LABEL,
            y: Math.round(
              ([...heatmap_column_means, ...heatmap_row_means].reduce((sum, val) => sum + val, 0) /
                [...heatmap_column_means, ...heatmap_row_means].length) *
                100
            ),
          },
        ],
      },
    ];

    // Calculates for row average (Adds new column average)
    const seriesWithRowMeans = pagedSeries.map((serie, serieIndex) => {
      const {name, data} = serie;
      return {
        name,
        data: [...data, {x: AVERAGE_LABEL, y: Math.round(heatmap_row_means[serieIndex] * 100)}],
      };
    });

    const newSeries = [...columnMeanSeries, ...seriesWithRowMeans];
    void refreshChart(newSeries);
    return newSeries;
  }, [heatmapData, xAxisPage, yAxisPage]);

  const showPagination = heatmapData && heatmapData?.data?.length;

  useEffect(() => {
    void refreshChart(series);
  }, [showLicensesPanel]);

  return (
    <div>
      <div className="tr-flex tr-p-4">
        <CustomLegend />
        <div className="tr-flex tr-gap-4 tr-ml-auto tr-items-center tr-w-full tr-max-w-[50%]">
          <label className="tr-mb-0">Y Value</label>
          <Select
            name="y-value"
            defaultValue={selectedYValue}
            value={selectedYValue}
            options={OPTIONS.filter((o) => o.value !== selectedXValue?.value)}
            onChange={(e: any) => {
              setSelectedYValue(e);
            }}
            required
          />
          <label className="tr-mb-0">X Value</label>
          <Select
            name="x-value"
            defaultValue={selectedXValue}
            value={selectedXValue}
            options={OPTIONS.filter((o) => o.value !== selectedYValue?.value)}
            onChange={(e: any) => {
              setSelectedXValue(e);
            }}
            required
          />
        </div>
      </div>
      {showPagination && heatmapData?.heatmap_columns.length > X_AXIS_PAGE_SIZE && (
        <div className="tr-flex tr-justify-center tr-mt-4">
          <HeatmapPagination
            page={xAxisPage}
            setPage={setXAxisPage}
            pagesCount={getPageCount(heatmapData?.heatmap_columns, X_AXIS_PAGE_SIZE)}
          />
        </div>
      )}

      {!heatmapData || heatmapData?.data?.length === 0 ? (
        <NoData src={selectedYValue && selectedXValue ? '/russel-thinking.svg' : '/russel-curious.svg'}>
          <div className="tr-flex tr-flex-col tr-items-center tr-gap-4">
            <span className="tr-font-normal tr-max-w-[350px]">
              {loading
                ? 'Data is loading...'
                : selectedYValue && selectedXValue
                ? 'There is no data for this selection. Please try selecting different values or date range.'
                : 'Select the two values above to compare them. '}
            </span>
            {!loading && (
              <a
                className="tr-text-trustle-link tr-no-underline"
                href="https://learn.trustle.com/tutorial/installing-microsoft-365/"
                target="_blank"
                rel="noreferrer"
              >
                Questions? See documentation
              </a>
            )}
          </div>
        </NoData>
      ) : (
        <div className="tr-flex">
          {showPagination && heatmapData.heatmap_rows.length > Y_AXIS_PAGE_SIZE && (
            <div className="tr-my-auto">
              <HeatmapPagination
                page={yAxisPage}
                setPage={setYAxisPage}
                direction="vertical"
                pagesCount={getPageCount(heatmapData.heatmap_rows, Y_AXIS_PAGE_SIZE)}
              />
            </div>
          )}
          <div className="tr-w-full">
            <Chart options={HEAT_MAP_OPTIONS} series={series} type="heatmap" height={400} />
          </div>
        </div>
      )}
    </div>
  );
}

function getPageCount(array: any[], pageSize: number) {
  const quotient = Math.floor(array.length / pageSize);
  const remainder = array.length % pageSize;
  return remainder > 0 ? quotient + 1 : quotient;
}

function paginateSeries(series: any[], xAxisPage: number, yAxisPage: number) {
  return series.slice(yAxisPage * Y_AXIS_PAGE_SIZE, (yAxisPage + 1) * Y_AXIS_PAGE_SIZE).map((serie) => {
    return {
      ...serie,
      data: serie.data.slice(xAxisPage * X_AXIS_PAGE_SIZE, (xAxisPage + 1) * X_AXIS_PAGE_SIZE),
    };
  });
}
